<template>
  <!-- ### 卡片(海报/宣传图)-操作台页面-主要通过mrm进入,内部员工使用 ### -->
  <div class="page-poster-index">
    <!-- 顶部导航 -->
    <header class="pc">
      <div class="header-con">
        <a :href="'/'+global.year+'/home'">
          <img src="../assets/img/header-logo.png" alt="" />
        </a>
        <div class="nav-list">
          <a :href="'/'+global.year+'/home'" target="_blank">返回首页</a>
          <a :href="'/'+global.year+'/speaker'" target="_blank">演讲嘉宾</a>
          <a :href="'/'+global.year+'/speech'" target="_blank">演讲专题</a>
          <a :href="'/'+global.year+'/schedule'" target="_blank">日程安排</a>
        </div>
      </div>
    </header>
    <header class="mob">
      <div class="header-con">
        <a :href="'/'+global.year+'/home'">
          <img src="../assets/img/header-logo.png" alt="" />
        </a>
        <div class="dropdown-wrapper" ref="posterNavDrop">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <!-- <i class="el-icon-arrow-down el-icon-menu" style="width:50px;height:50px;"></i> -->
              <img class="menu" src="../assets/img/header-menu.png" alt="" />
              <!-- <img class="menu" src="../assets/img/header-menu-white.png" alt="" /> -->
            </span>
            <el-dropdown-menu slot="dropdown" class="poster-nav-dropdown-menu">
              <el-dropdown-item>
                <router-link class="nav-item" :to="`/${global.year}/home`">返回首页</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link class="nav-item" :to="`/${global.year}/speaker`">演讲嘉宾</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link class="nav-item" :to="`/${global.year}/speech`">演讲专题</router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link class="nav-item" :to="`/${global.year}/schedule`">日程安排</router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </header>
    <Poster
      v-if="showPage"
      :_cardids="cardIdCache.id"
      :_selectparams="JSON.stringify(selectParams)"
      :_origindata="global.deepClone(originData)"
      :_datalist="global.deepClone(dataList)"
      @typechange="changeType"
      @idchange="changeOriginData"
      :key="`${selectParams.sid}_${selectParams.type}`"
    ></Poster>
    <p v-else class="page-blank">数据加载中...</p>
  </div>
</template>

<script>
import { id_arr, cardKeyword, getCardIdInfo, getSueType, getSueArr, getSueLecturer, getSpeechInfoNew, getAllCourse, getScheduleInfo, scheduledifference, scheduleDifferenceId } from '../utils/api.js';

export default {
  name: 'PosterIndex',
  props: [ 'poster_sid', 'poster_type', 'poster_vid', 'poster_cid', 'poster_did', 'poster_id', 'poster_year' ], // props从$route获取,已在路由中进行配置,必填项为 cardid 或 sid/year+type+id/detailid
  components: {
    Poster: () => import('./Poster.vue')
  },
  data() {
    return {
      year: '',
      sid: '',
      type: '',
      id_arr: id_arr,
      cardIdCache: { isReady:false, id:{ } },
      propsParams: {}, // select选项的props参照
      selectParams: {}, // select选项
      originData: {}, // select对应的原始数据
      showPage: false,
      formatData: {
        subject:[],
        course:[],
        case:[],
        schedule:{},
        isDifference: { subject:false, schedule:false, course:false },
      }, // dataList的格式化数据
      dataList: {
        subject: [],
        course: [],
        case: [],
        schedule: {},
        scheduleDifference: {}, // 特殊排课-api+推荐位
        isReady: { subject:false, schedule:false, opening:false, course:false, difference:false },
        // isDifference: { subject:false, schedule:false, course:false },
      },
    }
  },
  computed: {
    propsData() {
      // 参数来源：
      // $route:  sid、        type、        cardid、    id、                    detailid 
      // props :  poster_sid、 poster_type、 poster_id、 poster_vid/poster_cid、 poster_did 
      // console.log(this.poster_year, this.poster_sid);
      // year, sid, type, cardId, cardDetailId, venueId, courseId, scheduleId
      // year, sid, type, cardid, detailid, venueid, courseid, scheduleid
      let list = {
        year: this.poster_year || false,
        sid: this.poster_sid || false,
        type: this.poster_type || false,
        cardid: this.poster_id || false,
        detailid: this.poster_did || false,
        venueid: this.poster_type=='subject' && this.poster_vid || false,
        courseid: this.poster_type=='course' && this.poster_cid || false,
        scheduleid: this.poster_type=='schedule' ? (
          (this.poster_scid||this.poster_scid===0||this.poster_scid==='0') ? this.poster_scid : false
        ) : false,
      };
      if(!list.type && !list.cardid) {
        list.type = list.detailid ? 'case' : 'subject';
      }
      if(!list.sid) {
        if(list.year) {
          list.sid = id_arr[list.year].sid;
        } else {
          list.sid = Object.values(id_arr).sort(this.global.toSortKey('sid',0,'string'))[0].sid;
          for(let k in id_arr) {
            if(list.sid == id_arr[k].sid) {
              list.year = k;
              break;
            }
          }
        }
      }
      return list;
    }
  },
  watch: {
    propsData: {
      handler(_new, _old) {
        // console.log('PosterIndex·watch·props_data: ', _new, _old, this.poster_year);
        if(_new && typeof _new==='object') {
          this.getParams(_new, _old);
        }
      },
      immediate: true,
      deep: true
    },
    propsParams: {
      handler(_new, _old) {
        // console.log('PosterIndex·watch·props_params: ', _new, _old);
        if(this.global.isObjectKeys(_new) && (!_old || (typeof _old==='object' && !this.global.toCompareObject(_new, _old)))) {
          // this.selectParams = this.global.deepClone(_new);
          this.selectParams = {
            year: _new.year,
            sid: _new.sid,
            type: _new.type,
            cardId: _new.cardid,
            cardDetailId: _new.detailid,
            venueId: _new.venueid,
            courseId: _new.courseid,
            scheduleId: _new.scheduleid
          }
        }
        if(this.global.isObjectKeys(_new) && _new.sid) {
          if(!_old || (_old.sid != _new.sid) || (_old.type != _new.type)) {
            if(_new.type=='subject') {
              this.getDifference(_new.year, _new.sid, _new.type);
            }
            if(_new.type=='course' || _new.type=='case') {
              this.getCases(_new.sid, _new.type);
            }
            if(_new.type=='schedule') {
              // this.getSchedule(_new.sid);
              this.getSubjects(_new.sid, _new.type);
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
    dataList: {
      handler(_new, _old) {
        // console.log('PosterIndex·watch·data_list: ', _new&&_new[this.selectParams.type], this.selectParams);
        if(
          this.global.isObjectKeys(_new) && 
          _new[this.selectParams.type] && 
          _new['isReady'][this.selectParams.type] && (
            (Array.isArray(_new[this.selectParams.type]) && _new[this.selectParams.type].length>0) || 
            this.global.isObjectKeys(_new[this.selectParams.type])
          )
        ){
          // console.log('1--- ', this.cardIdCache.id[this.selectParams.sid]);
          this.originData = {};
          if(this.selectParams.type == 'subject') {
            if(!this.selectParams.venueId) {
              // 触发selectParams-watch -> 更新originData
              this.selectParams.venueId = _new[this.selectParams.type][0].id || false;
            } else {
              // 未触发selectParams-watch -> 手动更新originData
              for(let item of _new[this.selectParams.type]) {
                if(item.id && item.id == this.selectParams.venueId) {
                  this.originData = this.global.deepClone(item);
                }
              }
            }
          }
          if(this.selectParams.type == 'course') {
            if(!this.selectParams.courseId) {
              // 触发selectParams-watch -> 更新originData
              this.selectParams.courseId = _new[this.selectParams.type][0].courseid || false;
            } else {
              // 未触发selectParams-watch -> 手动更新originData
              for(let item of _new[this.selectParams.type]) {
                if(item.courseid && item.courseid == this.selectParams.courseId) {
                  this.originData = this.global.deepClone(item);
                }
              }
            }
          }
          if(this.selectParams.type == 'case') {
            if(!this.selectParams.cardDetailId) {
              this.selectParams.cardDetailId = this.cardIdCache.id[this.selectParams.sid]['casedetailid'] || false;
            }
          }
          if(this.selectParams.type == 'schedule') {
            if(!this.selectParams.scheduleId && this.selectParams.scheduleId!==0 && this.selectParams.scheduleId!=='0') {
              this.selectParams.scheduleId = 0;
            } else {
              if(_new[this.selectParams.type] && _new[this.selectParams.type][this.selectParams.scheduleId]) {
                this.originData = this.global.deepClone(_new[this.selectParams.type][this.selectParams.scheduleId]);
              }
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
    // originData: {
    //   handler(_new, _old) {
    //     if(this.global.isObjectKeys(_new)){
    //       // console.log('PosterIndex·watch·origin_data: ', _new, _old)
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // },
    selectParams: {
      handler(_new, _old) {
        if(this.global.isObjectKeys(_new)){
          // console.log('PosterIndex·watch·select_params: ', _new, _old, this.dataList['isReady'], this.dataList[_new.type]);
          this.originData = {};
          if(this.dataList['isReady'] && this.dataList['isReady'][_new.type]) {
            if(_new.type == 'subject') {
              for(let item of this.dataList[_new.type]) {
                if(item.id && item.id == _new.venueId) {
                  this.originData = this.global.deepClone(item); // JSON.parse(JSON.stringify(item));
                }
              }
            }
            if(_new.type == 'course') {
              for(let item of this.dataList[_new.type]) {
                if(item.courseid && item.courseid == _new.courseId) {
                  this.originData = this.global.deepClone(item); // JSON.parse(JSON.stringify(item));
                }
              }
            }
            if(_new.type == 'schedule') {
              if(this.dataList[_new.type] && this.dataList[_new.type][_new.scheduleId]) {
                this.originData = this.global.deepClone(this.dataList[_new.type][_new.scheduleId]);
              }
            }
          }
          this.showPage = true;
        }
      },
      immediate: true,
      deep: true
    },
  },
  created() {
    this.originData = {};
    this.formatData = {
      subject: [],
      course: [],
      case: [],
      schedule: {},
      isDifference: { subject:false, schedule:false, course:false },
    };
    this.dataList = {
      subject: [],
      course: [],
      case: [],
      schedule: {},
      scheduleDifference: {}, // 特殊排课-api+推荐位
      isReady: { subject:false, schedule:false, opening:false, course:false, difference:false },
      // isDifference: { subject:false, schedule:false, course:false },
    };
  },
  methods: {
    changeType(res) {
      console.log('PosterIndex·method·change_type ', res, this.propsParams);
      let flag = this.global.deepClone(this.propsParams);
      flag.type = res;
      flag.cardid = this.cardIdCache.id[flag.sid][res] || flag.cardid;
      flag.venueid = false;
      flag.courseid = false;
      flag.scheduleid = false;
      flag.detailid = false;
      this.propsParams = this.global.deepClone(flag);
      this.$forceUpdate();
    },
    changeOriginData(res) {
      console.log('PosterIndex·method·change_origin_data ', res);
      let _params = this.global.deepClone(this.selectParams); // JSON.parse(JSON.stringify(this.selectParams));
      if(res.type == 'subject') {
        console.log('1-subject ', res.id);
        _params.type = 'subject';
        _params.venueId = res.id;
      }
      if(res.type == 'course') {
        console.log('2-course ', res.id);
        _params.type = 'course';
        _params.courseId = res.id;
      }
      if(res.type == 'case') {
        console.log('3-case ', res.id);
        _params.type = 'case';
        _params.cardDetailId = res.id || false;
      }
      if(res.type == 'schedule') {
        console.log('4-schedule ', res.id);
        _params.type = 'schedule';
        _params.scheduleId = res.id;
      }
      this.selectParams = _params;
      this.$forceUpdate();
    },

    // 组件参数-初始化/更新
    getParams(baseNew, baseOld) {
      if(!baseOld || (baseOld.sid != baseNew.sid)) {
        this.setCardIdInfo(baseNew); // 初始化 propsParams 和 cardIdCache
      } else {
        this.setParams(baseNew); // 更新 propsParams
      }
    },

    // 更新 propsParams (即侧边栏年份以外的选项切换 - 'typechange' or 'idchange')
    setParams(base) {
      // console.log(base, this.propsParams, this.cardIdCache);
      if(!base.cardid) {
        console.log('change, but cardid is null >>>'); // 切换为无模板的类型
        this.propsParams = {
          year: this.propsParams.year,
          sid: this.propsParams.sid,
          type: base.type || false,
          cardid: false,
          detailid: false,
          venueid: false,
          courseid: false,
          scheduleid: false
        };
      } else if(base.cardid && (base.cardid != this.propsParams.cardid)) {
        // 切换·cardid(即类型)
        let type_new = base.type, type_flag = false;
        for(let s in this.cardIdCache.id) {
          for(let t in this.cardIdCache.id[s]) {
            if(this.cardIdCache.id[s][t] == base.cardid) {
              type_flag = true;
              if(type_new != t){
                type_new = t;
              }
              break;
            }
          }
          if(type_flag) break;
        }
        this.propsParams = {
          year: this.propsParams.year,
          sid: this.propsParams.sid,
          type: type_new,
          cardid: base.cardid,
          detailid: base.detailid || false,
          venueid: false,
          courseid: false,
          scheduleid: false
        };
      } else {
        // 切换·id(即venueid/courseid/scheduleid)
        if(base.venueid!==false && (this.propsParams.venueid != base.venueid)) {
          this.propsParams.venueid = base.venueid;
        }
        if(base.courseid!==false && (this.propsParams.courseid != base.courseid)) {
          this.propsParams.courseid = base.courseid;
        }
        if(base.scheduleid!==false && (this.propsParams.scheduleid != base.scheduleid)) {
          this.propsParams.scheduleid = base.scheduleid;
        }
      }
    },
    // 初始化 propsParams 和 cardIdCache
    setCardIdInfo(_params) {
      var params = this.global.isObjectKeys(_params) ? this.global.deepClone(_params) : {};
      if(!params.sid) {
        let nearYear = Object.keys(id_arr).sort((a,b)=>{return a<b;})[0];
        params = {
          year: nearYear,
          sid: id_arr[nearYear].sid,
          type: params.detailid ? 'case' : 'subject',
          cardid: false,
          detailid: params.detailid || false,
          venueid: false,
          courseid: false,
          scheduleid: false
        };
      } else {
        for(let y in id_arr) {
          if(id_arr[y].sid == params.sid) {
            if(params.year != y) {
              params.year = y;
            }
            break;
          }
        }
      }
      this.$http.jsonp(getCardIdInfo + `?sid=${params.sid}`).then((res) => {
        return res.json();
      }).then((res) => {
        // console.log('PosterIndex·methods·set_card_id_info', res)
        this.propsParams = {};
        this.cardIdCache.id = {};
        this.cardIdCache.id[params.sid] = { subject:false, course:false, case:false, schedule:false, casedetailid:false };
        if(res.status = '200' && this.global.isObjectKeys(res.data)) {
          // console.log(params, res.data, cardKeyword);
          // 按照id降序排序，使同一类型优先获取新建的card（新建的card的id数值更大）
          var dataArr = Object.values(res.data).sort(this.global.toSortKey('id',0,'number'));
          for(let item of dataArr) {
            let key = '';
            for(let k in cardKeyword) {
              if(item.typeName && item.typeName.includes(cardKeyword[k])) {
                key = k;
                break;
              }
            }
            // 从mrm入口进入时sid+type+cardid为必须参数(case类型同时存在detailid)，点击侧边栏-切换年份时要强制刷新页面则sid为必须参数/切换类型或id时由$emit触发不改变路径显示
            // 同一类型可能被创建多个(规则上不允许但有可能会发生)故会有路径中cardid对应card非最新的情况
            // 缓存 cardId -> 同一类型 优先取路径中的cardid，其次取res.data的键(id)且优先取最大值(即新建的card)：
            if(!this.cardIdCache.id[params.sid][key]) {
              this.cardIdCache.id[params.sid][key] = item.id;
            }
            if(params.cardid && params.cardid==item.id) {
              params.type = key;
            }
            if(params.type && params.type==key) {
              if(!params.cardid) {
                params.cardid = item.id;
              } else if(params.cardid != item.id) {
                this.cardIdCache.id[params.sid][key] = parseInt(params.cardid);
              }
            }
            // 此接口默认获取case类型下最新的一个cardDetailId，当一个cardId下存在多个cardDetailId时，会存在detailid对应card-detail非最新的情况
            // 缓存case类型的cardDetailId —> 优先取路径中的detailid再取cardDetailId：
            if(key == 'case' && this.cardIdCache.id[params.sid][key]==item.id) {
              this.cardIdCache.id[params.sid]['casedetailid'] = item.cardDetailId;
              if((params.cardid == item.id) && params.detailid && (params.detailid != item.cardDetailId)) {
                this.cardIdCache.id[params.sid]['casedetailid'] = params.detailid;
              }
            }
            // if(key == 'case') {
            //   if(params.detailid) {
            //     this.cardIdCache.id[params.sid]['casedetailid'] = params.detailid;
            //   } else if(item.cardDetailId) {
            //     this.cardIdCache.id[params.sid]['casedetailid'] = item.cardDetailId;
            //   }
            // }
          }
        }
        this.propsParams = this.global.deepClone(params);
        this.cardIdCache.isReady = true;
      })
    },


    getPeriod(times) {
      let period = 'am';
      if(times && times.length > 0) {
        let hours = times.slice(0,2);
        if(!hours.includes(':') && !hours.includes('：')) {
          // let minutes = times.slice(3,5);
          if(hours > '12') {
            period = 'pm';
          }
        }
      }
      return period;
    },
    // 获取数据列表
    getSubjects(sid, type) {
      this.$http
        .jsonp(getSpeechInfoNew + sid)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.errno == 0 && data.data && Array.isArray(data.data) && data.data.length > 0) {
            // console.log("subject-data: ", type, data.data);
            // 初始化数据-subject
            // this.dataList[type] = data.data;
            var base = JSON.parse(JSON.stringify(data.data));
            // format：
            let dayList=[], groupList=[];
            for(let index in base) {
              if(!dayList.includes(base[index].date)) {
                dayList.push(base[index].date);
              }
              if(!groupList.includes(base[index].groupId)) {
                groupList.push(base[index].groupId);
              }
            }
            dayList = dayList.sort((a,b)=>{return a<b;});
            groupList = groupList.sort((a,b)=>{return a<b;});
            // subject类型需插入特殊排课
            if(type=='subject' && this.dataList['isReady']['difference'] && this.dataList.scheduleDifference) {
              // console.log('subject-diff ', base, this.dataList.scheduleDifference, this.propsParams);
              let this_year = this.propsParams.year;
              for(let k in id_arr) {
                if(sid == id_arr[k].sid) {
                  this_year = k;
                  break;
                }
              }
              for(let diff_key in this.dataList.scheduleDifference) {
                let diff_year = diff_key.split('_')[0];
                let diff_day = diff_key.split('_')[1];
                let diff_group = diff_key.split('_')[2];
                let diff_period = diff_key.split('_')[3];
                if(diff_year == this_year) {
                  let flag_date, flag_groupid, flag_period;
                  for(let index in base) {
                    flag_date = dayList.indexOf(base[index].date);
                    flag_groupid = base[index].groupId;
                    flag_period = this.getPeriod(base[index].times);
                    if((flag_date||flag_date===0) && (flag_date+1)==diff_day && diff_group==flag_groupid && diff_period==flag_period) {
                      // console.log( diff_key, base[index], this.dataList.scheduleDifference[diff_key].courses );
                      let courses = [];
                      for(let course of this.dataList.scheduleDifference[diff_key].courses) {
                        courses.push({
                          averageScore: null,
                          calculationTime: null,
                          company: course.lecturers[0].company,
                          courseTitle: course.courseTitle,
                          courseid: '',
                          date: base[index].date,
                          endTime: course.times[1],
                          hash: null,
                          id: '',
                          name: course.lecturers[0].name,
                          position: course.lecturers[0].position,
                          sid: this.propsData.sid,
                          snid: base[index].id,
                          sort: '0',
                          startTime: course.times[0],
                          tag: 'default',
                          thumbs: course.lecturers[0].thumbs || '',
                          logo: course.lecturers[0].logo || '',
                          title: course.courseTitle
                        });
                      }
                      base[index].schedulingCourse = courses.sort(this.global.toSortKey('startTime',1,'string'));
                    }
                  }
                }
              }
              this.dataList[type] = base;
            }
            // 日程类型需格式化数据如 { 0: { 'datas': { 'am': {...}, 'pm': {...} }, 'date': 170000000000 } }
            if(type=='schedule') {
              let schedule = {};
              for(let d in dayList) {
                schedule[d] = { 'datas': { 'am': {}, 'pm': {} }, 'date': dayList[d] };
                for(let g of groupList) {
                  schedule[d]['datas']['am'][g] = {};
                  schedule[d]['datas']['pm'][g] = {};
                }
              }
              let flag_date, flag_groupid, flag_period;
              for(let item of base) {
                flag_date = dayList.indexOf(item.date);
                flag_groupid = item.groupId;
                flag_period = this.getPeriod(item.times);
                if(flag_date>=0) {
                  if(schedule[flag_date]['datas'][flag_period] && schedule[flag_date]['datas'][flag_period][flag_groupid]) {
                    // schedule[flag_date][flag_groupid].push(item);
                    schedule[flag_date]['datas'][flag_period][flag_groupid] = item;
                  }
                }
              }
              // console.log('⭐️⭐️⭐️', schedule);
              this.dataList[type] = this.global.deepClone(schedule);
            }
          }
          this.dataList['isReady'][type] = true;
        })
    }, 
    getCases(sid, type) {
      this.$http
        .jsonp(getAllCourse + sid)
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.errno === "0") {
            // console.log("case-data: ", data.data);
            // 初始化数据-course/case
            if(type) {
              this.dataList[type] = data.data;
            } else {
              this.dataList['course'] = data.data;
              this.dataList['case'] = data.data;
            }
          }
          if(type) {
            this.dataList['isReady'][type] = true;
          } else {
            this.dataList['isReady']['course'] = true;
            this.dataList['isReady']['case'] = true;
          }
        });
    }, 
    getSchedule(sid) {
      // 开幕式- id_arr[this.posterParams.year] - giac无开幕式
      let url = getScheduleInfo + sid;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          if (res.errno == '0') {
            // console.log("schedule-data: ", res.data);
            // 初始化数据-schedule
            this.dataList['schedule'] = res.data;
          }
          this.dataList['isReady']['schedule'] = true;
        })
    },
    // 特殊排课
    getDifference(_year, _sid, _type) {
      if(_year && scheduledifference(_year) && typeof scheduledifference(_year)==='object') {
        let url = getSueType + scheduleDifferenceId;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            if (res.errno == '0' && res.data) {
              let type = '';
              let typeList = [];
              for(let item of res.data) {
                type = item.typeName && typeof item.typeName==='string' && item.typeName.toLocaleLowerCase();
                if(type && type.includes('giac')) {
                  typeList.push({id:item.id, name:type.split('#') && type.split('#')[1]});
                }
              }
              this.getScheduleDifference(_year, typeList, _sid, _type);
            }
          })
      } else {
        // console.log('PosterIndex·method· ⭐️ isReady.difference')
        this.dataList['isReady']['difference'] = true;
        this.getSubjects(_sid, _type);
      }
    },
    getScheduleDifference(_year, typeList, _sid, _type) {
      if(typeList && typeList.length>0) {
        this.dataList.scheduleDifference = scheduledifference(_year);
        for(let index in Object.keys(this.dataList.scheduleDifference)) {
          this.dataList.scheduleDifference[Object.keys(this.dataList.scheduleDifference)[index]].courses = [];
          this.$forceUpdate();
        }
        // console.log("Difference ", typeList, this.dataList.scheduleDifference);
        // let url = getSueArr + scheduleDifferenceId; // res.data[0]
        let url = getSueLecturer + scheduleDifferenceId; // res.data[scheduleDifferenceId]
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            // console.log("difference-data: ", res, typeList, this.dataList.scheduleDifference);
            if (res.errno == '0' && res.data) {
              for(let typeItem of typeList) {
                if(this.dataList.scheduleDifference[typeItem.name]) {
                  this.dataList.scheduleDifference[typeItem.name].courses = [];
                  this.dataList.scheduleDifference[typeItem.name].timeStamp = new Date().getTime();
                  let course = {}, lecturers=[], files=[], lecturer='', times=[], titles=[], title='';
                  // for(let index in res.data[0]) {
                  //   let course = res.data[0][index];
                  for(let index in res.data[scheduleDifferenceId]) {
                    let course = res.data[scheduleDifferenceId][index];
                    // console.log('scheduleDifference--- ', course);
                    lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                    files = course.file && course.file.length>0 && JSON.parse(course.file) || [];
                    if(course.content_note && course.content_note.length>0) {
                      times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                      // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                    }
                    this.dataList.scheduleDifference[typeItem.name].courses.push({
                      courseTitle: course.title,
                      lecturers: [],
                      times: times,
                    });
                    for(let lindex in lecturers) {
                      lecturer = lecturers[lindex] || '';
                      // title = titles[lindex] || '';
                      if(lecturer.split(' || ')[0] && lecturer.split(' || ')[0].trim() !== '客户技术高层') {
                        this.dataList.scheduleDifference[typeItem.name].courses[index].lecturers.push({
                          name: lecturer.split(' || ')[0] || '',
                          company: lecturer.split(' || ')[1] || '',
                          position: lecturer.split(' || ')[2] || '',
                          thumbs: files[0] && files[0].fileUrl || '',
                          logo: files[1] && files[1].fileUrl || ''
                          // courseTitle: title, // 不需要显示讲师议题
                        });
                      }
                    }
                  }
                }
              }
            }
            // console.log('PosterIndex·method· ⭐️⭐️ isReady.difference')
            this.dataList['isReady']['difference'] = true;
            this.getSubjects(_sid, _type);
          })
      } else {
        // console.log('PosterIndex·method· ⭐️⭐️⭐️ isReady.difference')
        this.dataList['isReady']['difference'] = true;
        this.getSubjects(_sid, _type);
      }
    }
  }
}
</script>

<style lang="scss">
.el-dropdown-menu.el-popper {
  &.poster-nav-dropdown-menu {
    width: 100vw;
    left: 0!important;
    padding-bottom: 15px;
    .el-dropdown-menu__item {
      line-height: 50px;
    }
    li a {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      color: #000000;
      display: block;
      border-bottom: 1px solid #eee;
    }
  }
  &.operation-menu-year {
    .el-dropdown-menu__item {
      padding-left: 16px;
      padding-right: 16px;
      p {
        margin-bottom: 0;
        font-weight: 500;
        color: #555;
      }
    }
  }
}

.page-poster-index {
  width: 100vw!important;
  min-height: 100vh!important;
  margin-top: 0!important;
  line-height: 1;
  text-align: left;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  overflow: hidden;
  --background-color-button: linear-gradient(90deg, #7987E8 0%, #00C3FE 100%);
  .page-blank {
    width: 100vw;
    height: 100vh;
    line-height: calc(50vh - 60px);
    padding-top: 60px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  header {
    .header-con {
      width: 100vw;
      height: 60px;
      padding: 0 30px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1100;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(51, 51, 51, .08);
      img {
        height: 100%;
      }
      .nav-list {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        a {
          margin: 5px 15px 0 25px;
          font-size: 16px;
          font-weight: 500;
          color: #00C3FE;
          text-decoration: underline!important;
          cursor: pointer;
        }
      }
      .dropdown-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
  .page-poster {
    width: 100vw;
    min-height: 100vh;
    margin-top: 0;
    padding-top: 60px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
  }
  .tips {
    width: 93%;
    line-height: 1;
    padding: 5px;
    padding-left: 15px;
    max-width: 400px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1100;
    background-color: #ffffff;
    border-top-right-radius: 10px;
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    span {
      margin-right: 2px;
    }
  }
  h1 {
    width: 100%;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
    white-space: nowrap;
  }
  .operation-area {
    // width: 100%;
    // max-width: 430px;
    width: 430px;
    height: 100%;
    min-width: 430px;
    // box-shadow: 0 2px 4px 0 rgba(51, 51, 51, .08);
    .operation-con {
      width: 100%;
      height: 100%;
      padding: 30px 0 30px 30px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(51, 51, 51, .08);
      h1 {
        padding-right: 30px;
        // padding-bottom: 25px;
        padding-bottom: 5px;
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 0;
          border-bottom: 1px solid #eee;
        }
      }
      ul {
        width: 100%;
        height: 100%;
        // padding-top: 15px;
        padding-top: 20px;
        padding-right: 30px;
        overflow: auto;
        li {
          margin-bottom: 0;
          padding-bottom: 8px;
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          &:nth-last-of-type(1) {
            padding-bottom: 35px;
          }
          label {
            padding: 6px 0 0 0;
            margin-bottom: 0;
            margin-right: 2px;
            font-size: 14px;
            color: #666;
            white-space: nowrap;
            vertical-align: middle;
            span {
              margin-right: 1px;
              color: red;
              vertical-align: middle;
            }
          }
        }
      }
      .operation-menus {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        &.select-menus {
          display: grid;
          .operation-menu {
            width: 100%;
            margin-right: 0;
          }
        }
        .operation-menu {
          width: fit-content;
          padding: 5px 9px;
          margin-right: 15px;
          margin-bottom: 12px;
          border-radius: 4px;
          border: 1px solid #dfdfdf;
          background-color: rgba(240, 240, 240, 0.6);
          cursor: pointer;
          .menu-item {
            font-size: 14px;
            font-weight: bold;
            color: #666;
            vertical-align: middle;
          }
          &.act {
            color: #ffffff;
            border-color: #00C3FE70;
            background-color: rgba(240, 240, 240, 0.3);
            background: var(--background-color-button);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            .menu-item {
              color: inherit;
              // text-shadow: 0 1px 2px #003177;
            }
          }
          &:hover {
            color: #00C3FE;
            border-color: #00C3FE;
            background-color: #fff;
            // background-color: rgba(240, 240, 240, 0.9);
            .menu-item {
              color: inherit;
              // background: transparent;
              // -webkit-background-clip: text;
              // -webkit-text-fill-color: #00C3FE;
            }
          }
        }
        .operation-tips {
          line-height: 18px;
          margin-top: 10px;
          margin-bottom: 0;
          font-size: 13px;
          color: #000000;
          opacity: 0.45;
          &:nth-of-type(1) {
            line-height: 1;
            margin-top: 7px;
          }
        }
      }
    }
  }
  .poster-page {
    h1 {
      width: auto;
    }
    .show-area {
      height: 100%;
      margin-right: 0;
      .card-preview {
        width: auto;
        height: auto;
        max-width: 375px;
        // max-width: 400px;
        max-height: calc(100vh - 120px);
        cursor: pointer;
      }
    }
  }
  .pc {
    display: block;
  }
  .mob {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;
    .pc {
      display: none;
    }
    .mob {
      display: block;
    }
    .tips {
      width: 100%;
    }
    .operation-area {
      transition: left 0.4s linear;
    }
    .operation-btn {
      transition: left 0.28s linear;
      width: auto;
      height: 45px;
      padding: 0 8px 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      // top: 63px;
      bottom: 30px;
      left: 0;
      z-index: 999;
      background: var(--background-color-button);
      text-shadow: 0 1px 2px #003177;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border: 2px solid #ffffff;
      border-left: none;
      cursor: pointer;
      span {
        display: block;
        padding: 3px 5px 3px 5px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 2px;
        white-space: nowrap;
        border-bottom: 1px solid #eee;
      }
      &.isspread {
        left: -80px;
      }
    }
    .operation-area {
      width: 100vw;
      min-width: 100vw;
      max-width: 100vw;
      position: relative;
      left: -100vw;
      z-index: 998;
      &.isspread {
        left: 0;
      }
      .operation-con {
        width: 93%;
        min-width: 300px;
        max-width: 500px;
        height: calc(100vh - 60px);
        padding-bottom: 0;
        position: relative;
        ul {
          padding-bottom: 30px;
        }
      }
      .close-icon {
        // width: 30px;
        // height: 30px;
        // display: block;
        padding: 7px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1000;
        border-radius: 50%;
        border: 1px solid #ccc;
        background: var(--background-color-button);
        color: #ffffff;
        opacity: 0.5;
      }
    }
    .poster-page {
      padding-top: 100px;
      padding-left: 30px;
      padding-right: 30px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      justify-content: center;
      >h1 {
        height: auto;
        font-size: 15px;
        margin-right: 0;
        position: fixed;
        top: 75px;
        left: 30px;
        span {
          font-size: 13px;
        }
      }
      .show-area {
        // height: 100%;
        .card-preview {
          height: 100%;
        }
      }
      .show-menus {
        height: auto;
        position: fixed;
        bottom: 30px;
        right: 0;
        .menu {
          opacity: 1;
        }
      }
      .img-preview img {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>